import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";
import React, { FC, Fragment, MouseEvent, useState } from "react";
import Logo from "../images/svgs/vessel-logo-blue.svg";
import { H1, H5 } from "./typography";

const navigation = [
  { name: "Why Vessel", href: "/why-vessel/" },
  //{ name: "Features", href: "/features/" },
  { name: "About us", href: "/about-vessel/" },
  { name: "Learn", href: "/learn/" },
];

type NavProps = {};

const Navbar: FC<NavProps> = () => {
  const [scrollTransform, setScrollTransform] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -40 && prevPos.y >= -40) {
      setScrollTransform(true);
    } else if (currPos.y >= -40 && prevPos.y < -40) {
      setScrollTransform(false);
    }
  }, []);
  return (
    <Popover>
      {({ open }: any) => (
        <>
          <div
            className={`bg-white fixed h-14 md:h-16 z-50 top-0 left-0 right-0 transition-shadow  ${
              scrollTransform ? "shadow-sm duration-500" : "duration-300"
            }`}
          >
            <nav
              className="flex mx-auto max-w-6xl items-center justify-between h-14 md:h-16 top-0 right-0 left-0 pt-2 pb-2 md:justify-center fixed z-40 w-full px-4 sm:px-6 bg-white"
              aria-label="Global"
            >
              <div className="flex mx-auto items-center flex-1 md:absolute  md:left-0 md:ml-6 md:mt:8 ">
                <div className="flex items-center justify-between w-full">
                  <Link to="/" className="flex items-end justify-between">
                    <H1 className={`md:text-3xl text-3xl font-semibold transition-all duration-500 ease-in-out overflow-hidden hidden xsm:inline-block ${scrollTransform ? "w-0" : "w-24"}`}>Vessel</H1>
                    <img
                      className="h-8 w-auto mb-1 transition-all duration-500 ease-in-out"
                      src={Logo}
                      alt=""
                      width={44}
                      height={40}
                    />
                  </Link>
                  <div className="-mr-1 flex items-center md:hidden">
                    <Popover.Button className="rounded-md p-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-7 w-7" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0 md:space-x-10 z-40 pt-2 pb-2 mr-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="font-medium text-gray-500 hover:text-gray-900"
                  >
                    {item.name}
                  </Link>
                ))}
                <span className="inline-flex rounded-md shadow-md">
                  <a
                    href="#"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md text-blue-500 bg-white hover:bg-gray-50"
                  >
                    Get the App
                  </a>
                </span>
              </div>
            </nav>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              static
              className="fixed top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button
                      onClick={(e: MouseEvent<HTMLButtonElement>) =>
                        e.currentTarget.blur()
                      }
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                    >
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-7 w-7" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <a
                  href="#"
                  className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100"
                >
                  Get the App
                </a>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Navbar;
