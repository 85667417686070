import React, { FC } from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import SEO, { SEOProps } from "./seo";

type LayoutProps = {
  children?: React.ReactNode;
} & SEOProps;

const Layout: FC<LayoutProps> = ({
  title,
  description,
  canonical,
  siteName,
  children,
  image,
  article,
  twitterUsername,
}: LayoutProps) => (
  <div className="relative overflow-hidden pt-6 pb-10 ">
    <SEO
      title={title}
      description={description}
      canonical={canonical}
      siteName={siteName}
      image={image}
      article={article}
      twitterUsername={twitterUsername}
    />

    <Navbar />
    <main className="relative mx-auto md:mt-16 mt-8 mb-8 overflow-hidden max-w-7xl">
      {children}
    </main>
    <Footer />
  </div>
);

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
