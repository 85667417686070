import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

export type SEOProps = {
  title: string;
  description: string;
  image?: string;
  siteName: string;
  twitterUsername?: string;
  article?: boolean;
  lang?: string;
  canonical: string;
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        defaultImage
        twitterSite
        defaultLang
        themeColor
      }
    }
  }
`;

const SEO: FC<SEOProps> = ({
  title,
  description,
  image,
  article,
  twitterUsername,
  siteName,
  lang,
  canonical,
}: SEOProps) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterSite,
    defaultSiteName,
    defaultLang,
    themeColor,
  } = site.siteMetadata;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    siteName: siteName || defaultSiteName,
    lang: lang || defaultLang,
  };
  return (
    <Helmet
      htmlAttributes={{ lang: seo.lang }}
      title={seo.title}
      titleTemplate={`%s | ${seo.siteName}`}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={article ? "article" : "website"} />
      <meta property="og:title" content={`${seo.title} | ${seo.siteName}`} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      <meta name="twitter:title" content={`${seo.title} | ${seo.siteName}`} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <link rel="canonical" href={`https://getvessel.co.uk/${canonical}/`} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
};

SEO.defaultProps = {
  image: undefined,
  article: false,
  twitterUsername: undefined,
  lang: undefined,
};

export default SEO;
