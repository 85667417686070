import React, { FC } from "react";

type TypoProps = {
  className?: string;
  colorClass?: string;
  children?: React.ReactNode;
};

const overrideText = (bp: string, str?: string) => {
  const regex = `\\b(${
    bp === "" ? "" : `${bp}:`
  }text-)(\\d)*(xs|sm|base|lg|xl)\\b`;
  return str && `${str}`.match(regex) !== null;
};

const overrideFont = (bp: string, str?: string) => {
  const regex = `\\b(${
    bp === "" ? "" : `${bp}:`
  }font-)(\\d)*(thin|extralight|light|normal|medium|semibold|bold|extrabold|black)\\b`;
  return str && `${str}`.match(regex) !== null;
};

const D1: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h1
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-7xl"
    }${overrideText("md", className) ? "" : " md:text-9xl"} ${className}`}
  >
    {children}
  </h1>
);

D1.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const D2: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h1
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-6xl"
    }${overrideText("md", className) ? "" : " md:text-8xl"} ${className}`}
  >
    {children}
  </h1>
);

D2.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H1: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h1
    className={`${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-5xl"
    }${overrideText("md", className) ? "" : " md:text-7xl"} ${className}
    ${overrideFont("", className) ? "" : " font-extrabold"}`}
  >
    {children}
  </h1>
);

H1.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H2: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h2
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-4xl"
    }${overrideText("md", className) ? "" : " md:text-6xl"} ${className}`}
  >
    {children}
  </h2>
);

H2.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H3: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h3
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-3xl"
    }${overrideText("md", className) ? "" : " md:text-5xl"} ${className}`}
  >
    {children}
  </h3>
);

H3.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H4: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h4
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-2xl"
    }${overrideText("md", className) ? "" : " md:text-4xl"} ${className}`}
  >
    {children}
  </h4>
);

H4.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H5: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h5
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-xl"
    }${overrideText("md", className) ? "" : " md:text-3xl"} ${className}`}
  >
    {children}
  </h5>
);

H5.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const H6: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <h6
    className={`font-extrabold ${colorClass || "text-gray-800"}${
      overrideText("", className) ? "" : " text-lg"
    }${overrideText("md", className) ? "" : " md:text-2xl"} ${className}`}
  >
    {children}
  </h6>
);

H6.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

const P: FC<TypoProps> = ({ className, children, colorClass }: TypoProps) => (
  <p
    className={`${colorClass || "text-gray-600"}${
      overrideText("", className) ? "" : " text-base"
    }${overrideText("sm", className) ? "" : " sm:text-lg"}${
      overrideText("md", className) ? "" : " md:text-xl"
    } ${className}`}
  >
    {children}
  </p>
);

P.defaultProps = {
  className: "",
  children: undefined,
  colorClass: undefined,
};

export { D1, D2, H1, H2, H3, H4, H5, H6, P };
